<template>

  <div class="page404_main">
    <div class="page_inner">

      <h1>404</h1>
      <h3>PAGE NOT FOUND</h3>
      <p>it look like your're lost...</p>
      <img src="assets/img/lost.svg" alt="">
      <router-link :to="{name: 'dashboard'}" class="btn blue">Take me out of here</router-link>

    </div>

  </div>

</template>

<script>
import {mapActions} from "vuex";

export default ({
  'name': 'page404',

  async created () {
   await this.fetchWhitelabelAppSettings()
  },
  methods: {
    ...mapActions(['fetchWhitelabelAppSettings'])
  }

})
</script>
